// const LOGIN_USER = "LOGIN_USER";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILED = "LOGIN_FAILED";
const LOG_OUT = "LOG_OUT";
const SET_USER_CREDENTIALS = "SET_USER_CREDENTIALS";

const ACCESS_TOKEN = "access_token";




export { LOGIN_FAILED, LOGIN_SUCCESS, LOG_OUT, ACCESS_TOKEN, SET_USER_CREDENTIALS }