export const env = {
    client_secret: 'qcm4CBlNWwp20O2RuXYGcVdj1Pgczq2JH7PZ2xhf',
    endpoints: {
        articles: 'api/articles',
        employees: 'api/employees',
        files: 'api/download',
        journals: 'api/journals',
        references: 'api/references',
        reports: 'api/reports',
        search: 'api/search',
        singleSearch: 'api/search/single'
    },
    // url: 'http://archive.com:8888/',
    url: 'https://sniish.bmnteam.com/'


};
